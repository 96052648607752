<template>
  <div class="root">
    <!-- <button @click="queueInfo.unlocking = !queueInfo.unlocking">test</button> -->
    <van-loading v-if="loading" class="loading" color="#1989fa" />
    <template v-else-if="userInfo">
      <!-- 没有marscode -->
      <div v-if="!marscode" class="card1">
        <div class="text1">
          {{ userInfo.name }}，您当前并没有关联Mars系统账号
        </div>
        <div class="hotline">
          可拨打:&nbsp;<a class="pn" href="tel:800-898-9688">800-898-9688</a>(座机)咨询
        </div>
      </div>
      <!-- 正在排队解锁 -->
      <div v-else-if="queueInfo" class="card1">
        <div class="text1">
          <div class="item1">
            <span class="label1">Mars Code:</span>
            <span class="value1">{{ marscode || "/" }}</span>
          </div>
          <div class="item1">
            <span class="label1">BA姓名:</span>
            <span class="value1">{{ userInfo.name }}</span>
          </div>
          <template v-if="queueInfo.unlocking">
            <div class="item1">
              <span class="label1">备注:</span>
              <span class="value1">处理中</span>
            </div>
            <div class="tip1">
              正在解锁Mars账号，请在{{ minute }}分钟后使用原密码重新登录
            </div>
            <div class="hotline">
              如仍有问题，请拨打:&nbsp;<a class="pn" href="tel:800-898-9688">800-898-9688</a>(座机)
            </div>
          </template>
        </div>
        <van-button v-if="!queueInfo.unlocking" class="btn" square type="info" @click="unlock">申请解锁</van-button>
      </div>
    </template>
  </div>
</template>

<script>
import { Dialog } from "vant"
import { auth, getToken } from "./auth"
import { getUserInfo } from "./api/user"
import { getQueueInfo, queue } from "./api/mars"

export default {
  name: "App",
  data() {
    return {
      loading: false,
      userInfo: null,
      queueInfo: null,
      queueTimer: null,
    }
  },
  async created() {
    this.init()
  },
  computed: {
    marscode() {
      if (!this.userInfo) return null
      return this.userInfo.marscode
    },
    minute() {
      if (!this.queueInfo) return 0
      return this.formatSeconds(this.queueInfo.waitingTime)
    }
  },
  methods: {
    formatSeconds(second) {
      if (second == null) return 0
      return Math.ceil(second / 60)
    },
    async init() {
      this.loading = true
      if (!getToken()) {
        auth(this.init)
        return
      }
      try {
        await Promise.all([this.getUserInfo(), this.getQueueInfo()])
        if (this.queueInfo && this.queueInfo.unlocking) {
          this.startQueue()
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async unlock() {
      this.loading = true
      try {
        const res = await queue()
        const { code } = res.data
        if (code === 0) {
          await this.getQueueInfo()
          this.startQueue()
          const minute = this.formatSeconds(this.queueInfo.waitingTime)
          Dialog.alert({
            message:
              `正在解锁Mars账号，请在${minute}分钟后使用原密码重新登录`,
            confirmButtonColor: "#1989FA",
            className: "tip-dialog",
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async getUserInfo() {
      const res = await getUserInfo()
      const { code, data } = res.data
      if (code === 0) {
        this.userInfo = data
      }
    },
    async getQueueInfo() {
      const res = await getQueueInfo()
      const { code, data } = res.data
      if (code === 0) {
        this.queueInfo = data
      }
    },
    startQueue() {
      const get = () => {
        clearTimeout(this.queueTimer)
        this.queueTimer = setTimeout(async () => {
          await this.getQueueInfo()
          // 解锁失败或者成功
          if (!this.queueInfo.unlocking) {
            clearTimeout(this.queueTimer)
          } else {
            get()
          }
        }, 30000)
      }
      get()
    },
  },
}
</script>

<style lang="scss" scoped>
$vw_base: 375px;

@function vw($px) {
  @return ($px / $vw_base) * 100vw;
}

.root {
  font-size: vw(14px);
  background-image: url("./assets/bg.png");
  background-size: 100% vw(218px);
  background-repeat: no-repeat;
  height: 100vh;
  overflow: auto;
  background-color: #edf1f6;
  padding: 0 vw(16px);
}

.loading {
  position: relative;
  top: vw(20px);
  text-align: center;
}

%card {
  background-color: rgba(255, 255, 255, 0.4);
  border: vw(1px) solid rgba(255, 255, 255, 0.5);
  border-radius: vw(16px);
}

.card1 {
  @extend %card;
  position: relative;
  top: vw(24px);
  padding: vw(228px) vw(50px) vw(70px);
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  justify-content: center;
  background-image: url("./assets/paidui.png");
  background-repeat: no-repeat;
  background-size: vw(105px) vw(94px);
  background-position: center vw(68px);
}

.text1 {
  font-size: vw(16px);
  font-weight: 500;
  color: #333333;
  word-break: break-all;
}

.btn {
  margin-top: vw(28px);
  padding: vw(10px) vw(40px);
  background: linear-gradient(90deg, #4fb5ec 0%, #33a7e5 100%);
  border-radius: vw(100px);
  font-size: vw(16px);
  color: #fff;
  text-align: center;
}

.hotline {
  margin: vw(62px) 0 vw(32px);
  font-size: vw(12px);
  font-weight: 400;
  color: #666666;
  text-align: center;
  white-space: nowrap;
}

.tip1 {
  font-size: vw(14px);
  padding: vw(14px) 0;
  color: #333333;
  border-bottom: vw(1px) dashed #d2dae3;
  text-align: center;
}

.item1 {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: vw(14px) 0;
  border-bottom: vw(1px) dashed #d2dae3;

  >.value1 {
    flex: 1 0 1px;
    font-size: vw(14px);
    font-weight: 500;
    color: #333333;
  }

  >.label1 {
    flex: 0 0 42%;
    font-size: vw(14px);
    font-weight: 400;
    color: #888888;
  }
}

.pn {
  font-weight: 400;
  color: #2a4e85;
}

.tip-dialog {
  .van-dialog__message {
    font-size: vw(16px);
  }
}
</style>
